import loggedReducer from './isLogged';
import userLocation from './userLocation';
import TaskerLocation from './TaskerLocation';
import {combineReducers} from 'redux';

const allReducers = combineReducers({
    isLogged : loggedReducer,
    userLocation : userLocation,
    TaskerLocation : TaskerLocation
})

export default allReducers;