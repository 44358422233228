import i18n from "i18next";
import {initReactI18next} from 'react-i18next';
import common_sv from './translations/sv.json';
import common_en from './translations/en.json';
import common_fr from './translations/fr.json';
import common_ar from './translations/ar.json';

const resources = {
    sv: {
        translation : common_sv
    },
    en: {
        translation : common_en
    },
    fr: {
        translation : common_fr
    },
    ar: {
        translation : common_ar
    }
}

i18n.use(initReactI18next)
.init({
    resources,
    lng: 'sv',
    keySeparator: false,
    interpolation: {
        escapeValue : false
    }
});

export default i18n;